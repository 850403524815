import React, { useRef, useCallback } from "react";
import "./css/certificate.css";
import { toPng } from "html-to-image";

const PrintLayoutDubai = ({ rowData }) => {
  // console.log("QR---", rowData.serialId)
  const ref = useRef(null);

  const downloadPng = useCallback(() => {
    if (ref.current === null) {
      return;
    }
    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        // console.log(dataUrl)
        const link = document.createElement("a");
        // console.log(link)
        link.download = `${rowData.serialId.split("-")[0]}`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref, rowData.serialId]);

  return (
    <>
      <div
        ref={ref}
        style={{ height: "55vh" }}
        className="mainr pagecut pt-5 text-center pb-5 pl-1 pr-1 d-flex flex-column justify-content-center"
      >
        <div className="row pl-3 pr-3" style={{ backgroundColor: "white" }}>
          <div
            style={{ height: "60vh" }}
            className="mt-2 pt-2 text-center pl-3 pr-3 d-flex flex-column justify-content-center align-items-start"
          >
            {rowData.qr ? (
              <div
                className="d-flex flex-column align-items-center justify-content-center"
                style={{ border: "5px solid black", padding: "3px" }}
              >
                <div
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{ border: "4px solid black", padding: "10px" }}
                >
                  <div style={{ width: "250px" }}>
                    <img
                      src={rowData.qr}
                      alt="QR"
                      style={{
                        objectFit: "contain",
                        width: "100%",
                      }}
                    />
                  </div>
                  <span>Embassy of Pakistan, Doha-Qatar</span>
                  <h3 className="px-5" style={{ fontWeight: "bold" }}>
                    {"DOH" + rowData.serialId}
                  </h3>
                </div>
              </div>
            ) : (
              <h3>Please upload qr for this user first!</h3>
            )}
          </div>
        </div>
      </div>

      <button
        onClick={() => {
          downloadPng();
        }}
      >
        Export as PNG
      </button>
    </>
  );
};

export default PrintLayoutDubai;
