import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import backend from "../../api";
import toast from "react-hot-toast";
import { Header } from "../../components/Navbar";
import {
  FaPlusCircle,
  FaPrint,
  FaEye,
  FaQrcode,
  FaCheck,
} from "react-icons/fa";
import MaterialTable from "../../components/MaterialTable";
import { useNavigate } from "react-router-dom";
import PrintLayoutDoha from "../../components/PrintLayoutDoha";

const Doha = (props) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [rollNo, setRollNo] = useState("");
  const [file, setFile] = useState("");
  const [users, setUsers] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [value, setValue] = useState(5816);
  const [ID, setID] = useState(users?.length);
  const [viewModal, setViewModal] = useState({ open: false, img: "" });
  const [qrModal, setQrModal] = useState({ open: false, qr: "" });
  const [serialID, setSerialID] = useState("");
  const navigate = useNavigate();

  const onOpenModal = () => setOpen(true);

  const fetchData = async () => {
    setDataLoading(true);
    try {
      const {
        data: { data },
      } = await backend.get("/user?country=doha");
      let students = data.filter((i) => i.role !== "admin");
      //   console.log(students);
      setUsers(students);
      if (data.length > 0) {
        let serial = data.slice(-1)[0].serialId;
        let number = parseInt(serial) + 1;
        setValue(number);
      }
      setDataLoading(false);
    } catch (err) {
      setDataLoading(false);
    } finally {
      setDataLoading(false);
    }
  };

  const fetchNewData = async () => {
    try {
      const {
        data: { data },
      } = await backend.get("/user?country=doha");
      let students = data.filter((i) => i.role !== "admin");
      // console.log(students.length , users.length)
      if (students.length > users.length) {
        // console.log("updated")
        setUsers(students);
        if (data.length > 0) {
          let serial = data.slice(-1)[0].serialId;
          let number = parseInt(serial) + 1;
          setValue(number);
        }
      }
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("currentUser")) {
      navigate("/");
    } else {
      setLoading(true);
      fetchData();
    }
  }, [navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchNewData();
    }, 300000);
    return () => clearInterval(interval);
  });

  const onCloseModal = () => {
    setName("");
    setRollNo("");
    setFile("");
    setID("");
    setOpen(false);
    setIsEdit(false);
    closeQrModal();
  };

  const onSaveHandler = async (data) => {
    if (!file || !rollNo || !name) {
      toast.error("Form not filled");
    } else {
      try {
        let currSerial = "" + value;
        let postData = {
          roll_no: rollNo,
          file: file,
          name: name,
          serialId: currSerial,
          country: "doha",
        };
        const {
          data: { data },
        } = await backend.post("/user/create/student", postData);
        const list = users;
        list.push(data);
        setUsers(list);
        let serial = await data.serialId;
        let number = parseInt(serial) + 1;
        setValue(number);

        onCloseModal();
        toast.success("Student added succesfully");
      } catch (error) {
        // if (error.response.status === 400) {
        //   const errorMessage = error.response.data.message;
        //   toast.error(errorMessage);
        // } else {
        toast.error("Error adding student");
        // }
      }
    }
  };

  const onEditHandler = async (val) => {
    if (!file || !rollNo || !name) {
      toast.error("Form not filled");
    } else {
      try {
        let postData = {
          roll_no: rollNo,
          file: file,
          name: name,
          country: "doha",
        };
        const {
          data: { data },
        } = await backend.put(`/user/${val.id}`, postData);
        const list = users;
        list[list?.length && list.findIndex((e) => e._id === data._id)] = data;
        setUsers(list);
        onCloseModal();
        toast.success("Student updated succesfully");
      } catch (err) {
        toast.error("network error");
      }
    }
  };

  const addQr = async (e) => {
    e.preventDefault();
    if (!qrModal.qr) {
      toast.error("Form not filled");
    } else {
      try {
        let postData = { qr: qrModal.qr };
        const {
          data: { data },
        } = await backend.put(`/user/${ID}`, postData);
        const list = users;
        list[list?.length && list.findIndex((e) => e._id === data._id)] = data;
        setUsers(list);
        onCloseModal();
        toast.success("Qr Added succesfully");
      } catch (err) {
        toast.error("network error");
      } finally {
        setQrModal({ open: false, qr: "" });
      }
    }
  };

  const _submit = (e) => {
    e.preventDefault();
    const data = {
      id: ID,
      name,
      rollNo,
      file,
    };

    if (isEdit) onEditHandler(data);
    else onSaveHandler(data);
  };

  function extractSubpath(url) {
    const domainEnd = url.indexOf("/", "https://".length);
    const filenameStart = url.lastIndexOf("/") + 1;
    const extractedValue = url.substring(domainEnd + 1, filenameStart);
    return extractedValue;
  }

  const fileUpload = async (e) => {
    let data = new FormData();
    Array.from(e.target.files).forEach((i) => {
      if (!isEdit) {
        data.append("newName", value + "");
      } else {
        const urlSubstring = extractSubpath(file);
        data.append("newName", serialID);
        data.append("urlSubstring", urlSubstring);
      }
      data.append("images", i);
    });
    try {
      setLoading(true);
      const {
        data: { images },
      } = await backend.post("/file/doha", data, {
        headers: {
          "content-type": `multipart/form-data`,
        },
      });
      setFile(images[0]);
    } catch (err) {
      toast.error("image not uploaded");
    } finally {
      setLoading(false);
    }
  };

  const qrUpload = async (e) => {
    let data = new FormData();
    Array.from(e.target.files).forEach((i) => {
      data.append("images", i);
    });
    try {
      setLoading(true);
      const {
        data: { images },
      } = await backend.post("/file/doha", data, {
        headers: {
          "content-type": `multipart/form-data`,
        },
      });
      setQrModal({
        ...qrModal,
        qr: images[0],
      });
    } catch (err) {
      toast.error("image not uploaded");
    } finally {
      setLoading(false);
    }
  };

  const closeViewModal = () => {
    setViewModal({
      img: "",
      open: false,
    });
  };

  const closeQrModal = () => {
    setQrModal({
      qr: "",
      open: false,
    });
  };

  return (
    <>
      <Header />
      <div style={{ padding: "6rem" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h2>Doha Users</h2>
          <button
            onClick={onOpenModal}
            style={{
              padding: "2vmin",
              backgroundColor: "#000428",
              color: "white",
              textTransform: "uppercase",
              cursor: "pointer",
            }}
          >
            Add user file <FaPlusCircle />
          </button>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          {dataLoading === true && "Loading Data...."}
        </div>

        <br />
        <div>
          <MaterialTable
            title="Students"
            columns={[
              { title: " ID", field: "id" },
              { title: "Serial Id", field: "serialId" },
              { title: "Name", field: "name" },
              { title: "Rollno", field: "roll_no" },
              { title: "File", field: "file" },
              { title: "Edit", field: "edit" },
              {
                type: "numeric",
                title: "View",
                render: (rowData) => (
                  <>
                    <button
                      style={{
                        backgroundColor: "white",
                        borderWidth: 0,
                        color: "#000428",
                        fontSize: "3min",
                      }}
                      onClick={() => {
                        window.open(rowData.file, "_blank");
                      }}
                    >
                      <FaEye />
                    </button>
                  </>
                ),
              },
              {
                type: "numeric",
                title: "QR",
                render: (rowData) => {
                  return (
                    <div>
                      <button
                        style={{
                          backgroundColor: "white",
                          borderWidth: 0,
                          color: "#000428",
                          fontSize: "3min",
                          flexDirection: "row",
                        }}
                        onClick={() => {
                          setQrModal({ qr: "", open: true });
                          setID(rowData._id);
                        }}
                      >
                        <FaQrcode />
                        {rowData.qr !== undefined ? (
                          <div style={{ color: "green", fontSize: "3min" }}>
                            {" "}
                            <FaCheck />
                          </div>
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  );
                },
              },
              {
                type: "numeric",
                title: "Print",
                render: (rowData) => (
                  <button
                    style={{
                      backgroundColor: "white",
                      borderWidth: 0,
                      color: "#000428",
                      fontSize: "3min",
                    }}
                    onClick={() => {
                      setViewModal({
                        img: rowData,
                        open: true,
                      });
                    }}
                  >
                    <FaPrint />
                  </button>
                ),
              },
            ]}
            data={
              users &&
              users.map((e, i) => {
                return {
                  id: i + 1,
                  name: e.name,
                  serialId: e.serialId,
                  roll_no: e.roll_no,
                  file: e.file,
                  qr: e.qr,
                  _id: e._id,
                  edit: (
                    <button
                      color={"secondary"}
                      style={{
                        padding: "1vmin",
                        backgroundColor: "#000428",
                        color: "white",
                        textTransform: "uppercase",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                      data-bs-toggle="modal"
                      href="#exampleModalToggle"
                      role="button"
                      onClick={() => {
                        setName(e.name);
                        setRollNo(e.roll_no);
                        setFile(e.file);
                        setID(e._id);
                        setSerialID(e.serialId);
                        setIsEdit(true);
                        onOpenModal();
                      }}
                    >
                      Edit file
                    </button>
                  ),
                };
              })
            }
          ></MaterialTable>
        </div>
        <Modal open={viewModal.open} onClose={closeViewModal} center>
          <PrintLayoutDoha rowData={viewModal.img}></PrintLayoutDoha>
        </Modal>
        <Modal open={qrModal.open} onClose={closeQrModal} center>
          <form onSubmit={addQr}>
            <h1
              style={{ color: "black", fontFamily: "Raleway", width: "100%" }}
            >
              Add Qr
            </h1>
            <input
              className="text"
              onChange={(e) => {
                qrUpload(e);
              }}
              type="file"
              placeholder="choose file"
              name="file"
              id="psw"
              accept="image/*"
            />
            <button
              type="submit"
              disabled={loading}
              style={{
                width: "100px",
                marginTop: "1rem",
                justifyContent: "center",
                marginRight: "80%",
                padding: "2vmin",
                backgroundColor: "#000428",
                color: "white",
                textTransform: "uppercase",
                cursor: "pointer",
              }}
              className="registerbtn"
            >
              Submit
            </button>
          </form>
        </Modal>
        <Modal open={open} onClose={onCloseModal} center>
          <form onSubmit={_submit}>
            <h1
              style={{ color: "black", fontFamily: "Raleway", width: "100%" }}
            >
              {isEdit ? "Edit" : "Add"} New User
            </h1>
            <input
              className="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="Enter Name"
              name="email"
              id="email"
              required
            />
            <input
              className="text"
              value={rollNo}
              onChange={(e) => setRollNo(e.target.value)}
              type="text"
              placeholder="Enter Roll no"
              name="rollNo"
              id="psw"
              required
            />
            {!isEdit && (
              <input
                disabled={true}
                className="text"
                value={"" + value}
                type="text"
                placeholder="Serial Number"
                name="serialNo."
                id="psw"
                required
              />
            )}
            <input
              className="text"
              onChange={(e) => {
                fileUpload(e);
              }}
              type="file"
              placeholder="choose file"
              name="file"
              id="psw"
              accept="application/pdf"
            />
            <button
              type="submit"
              disabled={loading}
              style={{
                width: "100px",
                marginTop: "1rem",
                justifyContent: "center",
                marginRight: "80%",
                padding: "2vmin",
                backgroundColor: "#000428",
                color: "white",
                textTransform: "uppercase",
                cursor: "pointer",
              }}
              className="registerbtn"
            >
              Submit
            </button>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default Doha;
