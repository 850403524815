import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import "./css/nav.css";
import { logout, useAuthDispatch } from "../Context";

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useAuthDispatch(); // read dispatch method from context

  const handleLogout = () => {
    logout(dispatch);
    navigate("/");
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      style={{
        background: "linear-gradient(-90deg, #170a2a 55%, #86c2c2 )",
        padding: "13px",
      }}
      variant="dark"
      sticky="top"
      className="mainNav"
    >
      <Navbar.Brand className="brand ml-3" href="#top">
        <div className="row d-flex flex-row align-items-center">
          {/* Logo */}
        </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="nav-link ml-auto text-uppercase">
          {localStorage.getItem("currentUser") ? (
            <>
              <Link className="nav-link" to={{ pathname: "/home" }}>
                Pakistan
              </Link>
              <Link className="nav-link" to={{ pathname: "/dubai" }}>
                Dubai
              </Link>
              <Link className="nav-link" to={{ pathname: "/doha" }}>
                Doha
              </Link>
              <Link
                className="nav-link"
                to={{ pathname: "/" }}
                onClick={() => handleLogout()}
              >
                Logout
              </Link>
            </>
          ) : (
            <Link
              className="nav-link"
              to={{ pathname: "/" }}
              onClick={() => handleLogout()}
            >
              Login
            </Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
