import "./App.css";
import { Login } from "./Admin/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Forgot } from "./Admin/Forgot";
import { Home } from "./Admin/Home";
import { AuthProvider } from "./Context";

// import { store } from './store';
import { Toaster } from "react-hot-toast";
import "react-responsive-modal/styles.css";
import Footer from "./components/Footer";
import Dubai from "./Admin/Dubai/Dubai";
import Doha from "./Admin/Doha/Doha";
function App() {
  return (
    <div className="App">
      <Toaster />
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/dubai" element={<Dubai />} />
            <Route path="/doha" element={<Doha />} />
            <Route path="/forgot" element={<Forgot />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
