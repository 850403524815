import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import backend from "../api";
import toast from "react-hot-toast";
import { Header } from "../components/Navbar";

export const Forgot = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const onSaveHandler = async (e) => {
    e.preventDefault();
    try {
      await backend.put("/user/password/forgot", { email: email });
      toast.success("Reset Password successful. Please check your email");
      navigate("/");
    } catch (error) {
      toast.error("Invalid credentials");
    }
  };

  return (
    <>
      <Header />
      <div className="main">
        <form
          onSubmit={onSaveHandler}
          className="login"
          style={{ border: "1px solid grey" }}
        >
          <h1 className="label">Forgot Password</h1>
          <input
            className="input"
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            placeholder="Enter Email"
            name="email"
            id="email"
            required
          />
          <button
            type="submit"
            style={{
              padding: "2vmin",
              backgroundColor: "#000428",
              color: "white",
              textTransform: "uppercase",
            }}
            className="registerbtn"
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};
